import Vue from 'vue';
// import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import Auth from '@aws-amplify/auth';
import axios from 'axios';

export class S3Helper {
  static async initS3 (config) {
    const credentials = await Auth.currentCredentials();

    return new S3({
      // return new S3({
      region: config.region,
      credentials: credentials,
      apiVersion: '2006-03-01',
      params: { Bucket: config.bucket }
    });
  }

  static prepareObjectKey (objectKey, s3Config) {
    if (typeof s3Config.objectKeyPrefix === 'string') {
      objectKey = s3Config.objectKeyPrefix + objectKey;
    }

    return objectKey;
  }

  static async getSignedURL (operation, objectKey, s3Config, params = null) {
    objectKey = this.prepareObjectKey(objectKey, s3Config);
    // console.warn('objectKey', objectKey);
    return new Promise(async (resolve, reject) => {
      const s3 = await this.initS3(s3Config);
      if (!params) params = {};
      params.Bucket = s3Config.bucket;
      params.Key = objectKey;

      // ToDo: remove ACL-Parameter
      // if (operation === 'putObject' /* || operation === 'uploadPart' */) {
      //   params.ACL = 'public-read';
      // }
      s3.getSignedUrl(operation, params, function (err, url) {
        if (err) {
          return reject(new Error('getSignedUrl failed:', params, err.message));
        }
        // console.warn('signedUrl', url);
        resolve(url);
      });
    });
  }

  static async initMultipartUpload (objectKey, s3Config) {
    objectKey = this.prepareObjectKey(objectKey, s3Config);
    return new Promise(async (resolve, reject) => {
      const s3 = await this.initS3(s3Config);
      let params = { Bucket: s3Config.bucket, Key: objectKey, ACL: 'public-read' };

      s3.createMultipartUpload(params, function (err, res) {
        if (err) {
          return reject(new Error('createMultipartUpload failed:', err));
        }
        resolve(res.UploadId);
      });
    });
  }

  static async completeMultipartUpload (uploadId, objectKey, s3Config, multipartData) {
    objectKey = this.prepareObjectKey(objectKey, s3Config);
    return new Promise(async (resolve, reject) => {
      const s3 = await this.initS3(s3Config);
      let params = {
        Bucket: s3Config.bucket,
        Key: objectKey,
        MultipartUpload: {
          Parts: multipartData
        },
        UploadId: uploadId /* UploadId from Endpoint 1 response */
      };
      s3.completeMultipartUpload(params, function (err, res) {
        if (err) {
          return reject(new Error('completeMultipartUpload failed:', err));
        }
        resolve(res);
      });
    });
  }

  static uploadFile (objectKey, file, s3Config, signedUrl = false) {
    if (signedUrl === true) {
      return this.uploadViaSignedUrl(objectKey, file, s3Config);
    } else {
      return this.upload(objectKey, file, s3Config);
    }
  }

  static uploadViaSignedUrl (objectKey, file, s3Config) {
    return new Promise(async (resolve, reject) => {
      try {
        let signedUrl = await this.getSignedURL('putObject', objectKey, s3Config);
        // console.warn('signedUrl', signedUrl);
        resolve(this.axiosUpload(signedUrl, file));
      } catch (err) {
        reject(err);
      }
    });
  }

  static axiosUpload (url, file, tries = 1) {
    return new Promise(async (resolve, reject) => {
      axios.put(url, file, {}).then((response) => {
        if (response.status !== 200) throw new Error('invalid response status: ' + response.status);
        resolve(response.headers.etag);
      }).catch((err) => {
        if (tries < 3) { return this.axiosUpload(url, file, tries + 1); }
        reject(err);
      });
    });
  }

  static upload (objectKey, file, s3Config) {
    objectKey = this.prepareObjectKey(objectKey, s3Config);
    return new Promise(async (resolve, reject) => {
      const s3 = await this.initS3(s3Config);

      const params = {
        Bucket: s3Config.bucket,
        Key: objectKey,
        Body: file
        // ACL: 'public-read'
      };

      try {
        await s3.putObject(params).promise();
        resolve(true);
      } catch (e) {
        Vue.prototype.$logger.error('web', 'uploadJson: ' + e.message, params);
        reject(e);
      }
    });
  }

  static async uploadBase64Object (objectKey, binary, s3Config, signedUrl = false) {
    let body = Buffer.from(
      binary.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );
    return this.uploadFile(objectKey, body, s3Config, signedUrl);
  }

  static uploadJson (objectKey, json, s3Config, signedUrl = false) {
    let body = JSON.stringify(json);
    return this.uploadFile(objectKey, body, s3Config, signedUrl);
  }

  static deleteObject (objectKey, s3Config) {
    objectKey = this.prepareObjectKey(objectKey, s3Config);
    return new Promise(async (resolve) => {
      const s3 = await this.initS3(s3Config);

      Vue.prototype.$logger.debug('web', 'Deleting "' + objectKey + '" from S3');
      s3.deleteObject({ Bucket: s3Config.bucket, Key: objectKey }, (err, data) => {
        if (err) {
          Vue.prototype.$logger.error('web', 'Deleting "' + objectKey + '" from S3 failed: ' + err.message);
          return resolve(null);
        }
        resolve(data != null);
      });
    });
  }

  static renameObject (oldKey, newKey, s3Config) {
    oldKey = this.prepareObjectKey(oldKey, s3Config);
    newKey = this.prepareObjectKey(newKey, s3Config);
    return new Promise(async (resolve, reject) => {
      const s3 = await this.initS3(s3Config);

      try {
        await s3.copyObject({ Bucket: s3Config.bucket, CopySource: s3Config.bucket + '/' + oldKey, Key: newKey }).promise();
        await s3.deleteObject({ Bucket: s3Config.bucket, Key: oldKey }).promise();
        resolve(true);
      } catch (e) {
        reject(new Error('renaming "' + oldKey + '" in S3 failed: ' + e));
      }
    });
  }

  static copyObject (oldKey, newKey, s3Config) {
    oldKey = this.prepareObjectKey(oldKey, s3Config);
    newKey = this.prepareObjectKey(newKey, s3Config);
    return new Promise(async (resolve, reject) => {
      const s3 = await this.initS3(s3Config);

      try {
        await s3.copyObject({ Bucket: s3Config.bucket, CopySource: s3Config.bucket + '/' + oldKey, Key: newKey }).promise();
        resolve(true);
      } catch (e) {
        reject(new Error('copying "' + oldKey + '" in S3 failed: ' + e));
      }
    });
  }

  static downloadObject (objectKey, s3Config) {
    objectKey = this.prepareObjectKey(objectKey, s3Config);
    return new Promise(async (resolve, reject) => {
      const s3 = await this.initS3(s3Config);

      Vue.prototype.$logger.debug('web', 'Downloading "' + objectKey + '" from S3');
      s3.getObject({ Bucket: s3Config.bucket, Key: objectKey }, (err, data) => {
        if (err) {
          Vue.prototype.$logger.error('web', 'Downloading "' + objectKey + '" from S3 failed: ' + err.message);
          reject(new Error('Downloading "' + objectKey + '" from S3 failed: ' + err));
        }
        if (!data) {
          Vue.prototype.$logger.error('web', 'Downloading "' + objectKey + '" from S3 failed: Object is null');
          resolve(null);
        } else {
          resolve(data.Body);
        }
      });
    });
  }

  static downloadJson (objectKey, s3Config) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await this.downloadObject(objectKey, s3Config);
        if (!data) {
          reject(new Error('json-File is null'));
        } else {
          const dataString = data.toString('utf8');
          const obj = JSON.parse(dataString);
          resolve(obj);
        }
      } catch (err) {
        reject(err);
      }
    });
  }
};
